/* You can add global styles to this file, and also import other style files */
/*@import "./indigo-pink.css";*/

/*@import "./assets/mat-theme.scss";*/

body {
  background-color: #000000;
  color: #ffffff;

  font-family: Roboto, sans-serif;
  font-weight: 300;

  margin: 0;

  height: fit-content;
  min-height: calc(100vh - 65px);
  margin-bottom: 65px;
}

@media only screen and (max-width: 500px) {
  body {
    margin-bottom: 0px;
  }
}

.image-dialog-container .mat-dialog-container {
  padding: 0;
  background-color: #222222;
}

.copyright-dialog-container .mat-dialog-container {
  background-color: #222222;
  color: white;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.cdk-global-overlay-wrapper{
  ::-webkit-scrollbar {
    
    
    width: 12px; /* Set the width of the scrollbar */
    height: 12px; /* Set the height of the horizontal scrollbar (if applicable) */
  }
  
  ::-webkit-scrollbar-thumb {
    
    background-color: #696969; /* Set the color of the scrollbar thumb */
    border-radius: 6px; /* Optional: rounded corners */
    border: 3px solid #3534349a; /* Optional: border around the thumb */
    padding-top: 5px; /* Optional: inset margin */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #5a59599a; /* Set the color of the track */
    border-radius: 6px; /* Optional: rounded corners */
  }
  
  ::-webkit-scrollbar-button {
    height: 2px;
    background-color: transparent; /* Set the color of the scrollbar buttons */
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent; /* Set the color of the corner if visible (for both scrollbars) */
  }
  
}

.cdk-overlay-pane
 {
  overflow-y: auto;
}
.mat-datetimepicker-popup {
  color: #000000de;
}/* For WebKit-based browsers (Chrome, Safari, Edge) */
