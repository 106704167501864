/* You can add global styles to this file, and also import other style files */
/*@import "./indigo-pink.css";*/

/*@import "./assets/mat-theme.scss";*/

body {
  background-color: #000000;
  color: #ffffff;

  font-family: Roboto, sans-serif;
  font-weight: 300;

  margin: 0;

  height: fit-content;
  min-height: calc(100vh - 65px);
  margin-bottom: 65px;
}

@media only screen and (max-width: 500px) {
  body {
    margin-bottom: 0px;
  }
}

.image-dialog-container .mat-dialog-container {
  padding: 0;
  background-color: #222222;
}

.copyright-dialog-container .mat-dialog-container {
  background-color: #222222;
  color: white;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
